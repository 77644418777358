const HeaderObj = {
    transformAnimations: {
        'nav-menu': {},
        'wrapper': {}
    },
    transformData: {
        'headerOpen': {
            'desktop': [
                "height: auto; transform: none; transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -381px, 0px) scale(1, 0.0103896); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -380.997px, 0px) scale(1, 0.0103983); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -380.176px, 0px) scale(1, 0.0125305); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -376.489px, 0px) scale(1, 0.0221055); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -368.808px, 0px) scale(1, 0.042056); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -355.632px, 0px) scale(1, 0.0762793); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -335.515px, 0px) scale(1, 0.128532); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -303.325px, 0px) scale(1, 0.212143); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -262.407px, 0px) scale(1, 0.318422); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -215.135px, 0px) scale(1, 0.441209); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -173.359px, 0px) scale(1, 0.549717); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -141.735px, 0px) scale(1, 0.631857); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -114.958px, 0px) scale(1, 0.701406); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -93.325px, 0px) scale(1, 0.757597); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -76.5283px, 0px) scale(1, 0.801225); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -61.956px, 0px) scale(1, 0.839075); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -49.7113px, 0px) scale(1, 0.87088); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -40.1433px, 0px) scale(1, 0.895732); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -31.5842px, 0px) scale(1, 0.917963); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -24.3401px, 0px) scale(1, 0.936779); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -18.6991px, 0px) scale(1, 0.951431); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -13.8416px, 0px) scale(1, 0.964048); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -9.75266px, 0px) scale(1, 0.974668); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -6.73407px, 0px) scale(1, 0.982509); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -4.18124px, 0px) scale(1, 0.98914); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -2.30986px, 0px) scale(1, 0.994); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -1.07612px, 0px) scale(1, 0.997205); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -0.284796px, 0px) scale(1, 0.99926); transform-origin: 50% 50% 0px;"
            ],
            'tablet': [
                "height: auto; transform: none; transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -393px, 0px) scale(1, 0.0100756); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -392.963px, 0px) scale(1, 0.0101689); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -392.65px, 0px) scale(1, 0.0109576); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -390.766px, 0px) scale(1, 0.0157038); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -385.194px, 0px) scale(1, 0.0297383); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -374.898px, 0px) scale(1, 0.0556726); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -357.518px, 0px) scale(1, 0.0994517); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -332.133px, 0px) scale(1, 0.163394); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -292.94px, 0px) scale(1, 0.262116); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -247.359px, 0px) scale(1, 0.376929); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -198.083px, 0px) scale(1, 0.50105); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -163.679px, 0px) scale(1, 0.587709); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -132.367px, 0px) scale(1, 0.666582); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -107.519px, 0px) scale(1, 0.729172); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -87.3414px, 0px) scale(1, 0.779996); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -71.5112px, 0px) scale(1, 0.819871); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -57.6719px, 0px) scale(1, 0.854731); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -46.141px, 0px) scale(1, 0.883776); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -37.0609px, 0px) scale(1, 0.906648); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -28.978px, 0px) scale(1, 0.927008); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -22.1843px, 0px) scale(1, 0.94412); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -16.8238px, 0px) scale(1, 0.957623); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -12.1384px, 0px) scale(1, 0.969425); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -8.43592px, 0px) scale(1, 0.978751); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -5.59326px, 0px) scale(1, 0.985911); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -3.30879px, 0px) scale(1, 0.991666); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -1.68792px, 0px) scale(1, 0.995748); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -0.650768px, 0px) scale(1, 0.998361); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -0.0956256px, 0px) scale(1, 0.999759); transform-origin: 50% 50% 0px;"
            ],
            'phone': [
                "height: auto; transform: none; transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -560px, 0px) scale(1, 0.0070922); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -559.978px, 0px) scale(1, 0.00713205); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -559.501px, 0px) scale(1, 0.00797686); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -555.116px, 0px) scale(1, 0.0157513); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -546.227px, 0px) scale(1, 0.0315127); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -529.394px, 0px) scale(1, 0.0613573); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -501.786px, 0px) scale(1, 0.110308); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -461.654px, 0px) scale(1, 0.181464); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -401.959px, 0px) scale(1, 0.287307); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -336.058px, 0px) scale(1, 0.404153); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -268.314px, 0px) scale(1, 0.524267); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -219.117px, 0px) scale(1, 0.611494); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -177.38px, 0px) scale(1, 0.685497); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -144.032px, 0px) scale(1, 0.744624); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -118.248px, 0px) scale(1, 0.79034); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -95.7901px, 0px) scale(1, 0.830159); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -78.0129px, 0px) scale(1, 0.861679); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -62.2042px, 0px) scale(1, 0.889709); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -49.0831px, 0px) scale(1, 0.912973); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -38.8227px, 0px) scale(1, 0.931165); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -29.4176px, 0px) scale(1, 0.947841); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -21.8667px, 0px) scale(1, 0.961229); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -15.9319px, 0px) scale(1, 0.971752); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -10.8728px, 0px) scale(1, 0.980722); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -6.92868px, 0px) scale(1, 0.987715); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -4.0678px, 0px) scale(1, 0.992788); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -1.94511px, 0px) scale(1, 0.996551); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -0.616912px, 0px) scale(1, 0.998906); transform-origin: 50% 50% 0px;",
                "height: auto; transform: translate3d(0px, -0.0440089px, 0px); transform-origin: 50% 50% 0px;"
            ]
        },
        'navOpen': {
            'desktop': [
                "transform: translate3d(0px, 37052.2px, 0px) scale(1, 96.25) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 37021.1px, 0px) scale(1, 96.1698) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 30655.3px, 0px) scale(1, 79.8053) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 17208.4px, 0px) scale(1, 45.2375) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 8860.56px, 0px) scale(1, 23.7778) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 4710.68px, 0px) scale(1, 13.1097) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 2637.49px, 0px) scale(1, 7.78019); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 1444.67px, 0px) scale(1, 4.71379); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 832.648px, 0px) scale(1, 3.14048); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 492.669px, 0px) scale(1, 2.2665) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 318.637px, 0px) scale(1, 1.81912); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 226.645px, 0px) scale(1, 1.58264); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 165.6px, 0px) scale(1, 1.42571) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 124.465px, 0px) scale(1, 1.31996) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 96.5064px, 0px) scale(1, 1.24809); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 74.6055px, 0px) scale(1, 1.19179); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 57.6748px, 0px) scale(1, 1.14826) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 45.2818px, 0px) scale(1, 1.11641); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 34.7643px, 0px) scale(1, 1.08937); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 26.2527px, 0px) scale(1, 1.06749) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 19.8578px, 0px) scale(1, 1.05105); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 14.507px, 0px) scale(1, 1.03729) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 10.1101px, 0px) scale(1, 1.02599); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 6.92517px, 0px) scale(1, 1.0178); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 4.27107px, 0px) scale(1, 1.01098) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 2.34795px, 0px) scale(1, 1.00604); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 1.09035px, 0px) scale(1, 1.0028); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 0.287968px, 0px) scale(1, 1.00074); transform-origin: 50% 50% 0px;",
                "transform: none; transform-origin: 50% 50% 0px;"
            ],
            'tablet': [
                "transform: translate3d(0px, 39398.2px, 0px) scale(1, 99.25) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 39033.1px, 0px) scale(1, 98.3395) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 38368.8px, 0px) scale(1, 96.6829) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 34785.5px, 0px) scale(1, 87.747) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 18749.5px, 0px) scale(1, 47.7569) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 12121.6px, 0px) scale(1, 31.2283) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 6318.79px, 0px) scale(1, 16.7576) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 3377.79px, 0px) scale(1, 9.42342); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 1913.39px, 0px) scale(1, 5.77154); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 1088.62px, 0px) scale(1, 3.71476); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 624.48px, 0px) scale(1, 2.55731); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 389.03px, 0px) scale(1, 1.97015); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 269.886px, 0px) scale(1, 1.67303); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 193.221px, 0px) scale(1, 1.48185); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 144.064px, 0px) scale(1, 1.35926); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 111.092px, 0px) scale(1, 1.27704); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 85.3868px, 0px) scale(1, 1.21293); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 66.1313px, 0px) scale(1, 1.16492); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 48.8208px, 0px) scale(1, 1.12175) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 39.3574px, 0px) scale(1, 1.09815) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 29.9107px, 0px) scale(1, 1.07459); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 23.2534px, 0px) scale(1, 1.05799); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 17.0599px, 0px) scale(1, 1.04254); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 12.4155px, 0px) scale(1, 1.03096); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 8.22965px, 0px) scale(1, 1.02052) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 5.26916px, 0px) scale(1, 1.01314); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 3.13302px, 0px) scale(1, 1.00781); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 1.62681px, 0px) scale(1, 1.00406); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 0.579933px, 0px) scale(1, 1.00145) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 0.0678809px, 0px) scale(1, 1.00017) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: none; transform-origin: 50% 50% 0px;"
            ],
            'phone' : [
                "transform: translate3d(0px, 79520px, 0px) scale(1, 141) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 79072.5px, 0px) scale(1, 140.212) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 70637.9px, 0px) scale(1, 125.363) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 35492.5px, 0px) scale(1, 63.4869) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 17456.5px, 0px) scale(1, 31.7332) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 8689.26px, 0px) scale(1, 16.298) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 4581.22px, 0px) scale(1, 9.06552) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 2562.09px, 0px) scale(1, 5.51073) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 1408.98px, 0px) scale(1, 3.48059) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 837.407px, 0px) scale(1, 2.47431); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 515.418px, 0px) scale(1, 1.90743) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 360.872px, 0px) scale(1, 1.63534); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 260.596px, 0px) scale(1, 1.4588); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 194.801px, 0px) scale(1, 1.34296) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 150.678px, 0px) scale(1, 1.26528) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 116.206px, 0px) scale(1, 1.20459) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 91.1779px, 0px) scale(1, 1.16052); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 70.411px, 0px) scale(1, 1.12396) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 54.1431px, 0px) scale(1, 1.09532); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 41.9883px, 0px) scale(1, 1.07392); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 31.2566px, 0px) scale(1, 1.05503) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 22.91px, 0px) scale(1, 1.04033) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 16.5113px, 0px) scale(1, 1.02907) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 11.1651px, 0px) scale(1, 1.01966) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 7.06461px, 0px) scale(1, 1.01244) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 4.12641px, 0px) scale(1, 1.00726) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 1.96569px, 0px) scale(1, 1.00346); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 0.621968px, 0px) scale(1, 1.0011) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, 0.0440089px, 0px); transform-origin: 50% 50% 0px;",
                "transform: none; transform-origin: 50% 50% 0px;"
            ],
        },
        'wrapperOpen': {
            'desktop': [
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -762px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -761.993px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -760.352px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -752.979px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -737.617px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -711.265px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -671.031px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -606.65px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -524.815px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -430.269px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -346.718px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -283.47px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -229.917px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -186.65px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -153.057px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -123.912px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -99.4227px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -80.2866px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -63.1685px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -48.6801px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -37.3981px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -27.6832px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -19.5053px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -13.4681px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -8.36248px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -4.61972px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -2.15225px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, -0.569592px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 40px; border-top-right-radius: 40px; transform: none; transform-origin: 50% 50% 0px;"
            ],
            'tablet': [
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -786px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -785.926px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -785.3px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -781.531px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -770.388px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -749.796px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -715.035px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -664.265px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -585.88px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -494.719px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -396.166px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -327.359px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -264.734px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -215.038px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -174.683px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -143.022px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -115.344px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -92.282px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -74.1219px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -57.956px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -44.3686px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -33.6476px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -24.2768px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -16.8718px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -11.1865px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -6.61758px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -3.37584px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -1.30154px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, -0.191251px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 40px; border-top-right-radius: 40px; transform: none; transform-origin: 50% 50% 0px;"
            ],
            'phone' : [
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -1120px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -1119.96px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -1119px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -1110.23px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -1092.45px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -1058.79px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -1003.57px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -923.308px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -803.917px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -672.115px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -536.627px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -438.235px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -354.76px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -288.064px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -236.497px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -191.58px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -156.026px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -124.408px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -98.1662px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -77.6454px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -58.8352px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -43.7333px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -31.8639px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -21.7455px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -13.8574px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -8.13559px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -3.89023px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -1.23382px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, -0.0880179px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 40px; border-top-right-radius: 40px; transform: none; transform-origin: 50% 50% 0px;"
            ],
        },
        'headerClose': {
            'desktop': [
                "height: 0.5rem; transform: none; transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 381px, 0px) scale(1, 96.25); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 380.851px, 0px) scale(1, 96.2127); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 378.641px, 0px) scale(1, 95.6603); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 378.179px, 0px) scale(1, 95.5448); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 364.199px, 0px) scale(1, 92.0498); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 355.632px, 0px) scale(1, 89.9081); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 345.413px, 0px) scale(1, 87.3533); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 318.192px, 0px) scale(1, 80.5481); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 281.27px, 0px) scale(1, 71.3175); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 234.377px, 0px) scale(1, 59.5942); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 189.523px, 0px) scale(1, 48.3808); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 152.909px, 0px) scale(1, 39.2273); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 125.149px, 0px) scale(1, 32.2873); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 101.726px, 0px) scale(1, 26.4314); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 80.4511px, 0px) scale(1, 21.1128); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 66.9103px, 0px) scale(1, 17.7276); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 54.4867px, 0px) scale(1, 14.6217); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 44.171px, 0px) scale(1, 12.0427); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 13.0597px, 0px) scale(1, 4.26493); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 8.90315px, 0px) scale(1, 3.22579); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 7.06206px, 0px) scale(1, 2.76552); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 0.284796px, 0px) scale(1, 1.0712); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: scale(1, 1.00083); transform-origin: 50% 50% 0px;"
            ],
            'tablet': [
                "height: 0.5rem; transform: none; transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 393px, 0px) scale(1, 99.25); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 392.963px, 0px) scale(1, 99.2407); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 392.789px, 0px) scale(1, 99.1973); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 392.567px, 0px) scale(1, 99.1418); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 390.307px, 0px) scale(1, 98.5768); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 384.287px, 0px) scale(1, 97.0718); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 374.111px, 0px) scale(1, 94.5278); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 356.292px, 0px) scale(1, 90.0731); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 328.214px, 0px) scale(1, 83.0535); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 290.129px, 0px) scale(1, 73.5322); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 241.759px, 0px) scale(1, 61.4396); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 195.493px, 0px) scale(1, 49.8731); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 159.706px, 0px) scale(1, 40.9266); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 127.732px, 0px) scale(1, 32.9329); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 104.93px, 0px) scale(1, 27.2324); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 85.1541px, 0px) scale(1, 22.2885); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 69.0177px, 0px) scale(1, 18.2544); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 56.2029px, 0px) scale(1, 15.0507); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 44.9863px, 0px) scale(1, 12.2466); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 35.4823px, 0px) scale(1, 9.87058); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 26.7806px, 0px) scale(1, 7.69515); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 21.7596px, 0px) scale(1, 6.4399); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 15.9541px, 0px) scale(1, 4.98853); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 11.7083px, 0px) scale(1, 3.92709); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 7.98371px, 0px) scale(1, 2.99593); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 5.29511px, 0px) scale(1, 2.32378); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 3.07773px, 0px) scale(1, 1.76943); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 1.52231px, 0px) scale(1, 1.38058); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 0.524371px, 0px) scale(1, 1.13109); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 0.0671925px, 0px) scale(1, 1.0168); transform-origin: 50% 50% 0px;"
            ],
            'phone': [
                "height: 0.5rem; transform: none; transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 560px, 0px) scale(1, 141); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 559.947px, 0px) scale(1, 140.987); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 559.7px, 0px) scale(1, 140.925); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 556.533px, 0px) scale(1, 140.133); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 548.239px, 0px) scale(1, 138.06); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 534.206px, 0px) scale(1, 134.551); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 511.405px, 0px) scale(1, 128.851); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 470.492px, 0px) scale(1, 118.623); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 417.421px, 0px) scale(1, 105.355); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 348.488px, 0px) scale(1, 88.1219); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 282.256px, 0px) scale(1, 71.564); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 227.572px, 0px) scale(1, 57.8929); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 186.277px, 0px) scale(1, 47.5693); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 153.208px, 0px) scale(1, 39.3019); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 124.456px, 0px) scale(1, 32.114); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 101.899px, 0px) scale(1, 26.4747); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 82.1787px, 0px) scale(1, 21.5447); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 65.748px, 0px) scale(1, 17.437); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 52.0552px, 0px) scale(1, 14.0138); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 41.2918px, 0px) scale(1, 11.3229); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 31.6112px, 0px) scale(1, 8.9028); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 23.6158px, 0px) scale(1, 6.90394); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 16.9888px, 0px) scale(1, 5.24719); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 11.7609px, 0px) scale(1, 3.94023); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 7.75624px, 0px) scale(1, 2.93906); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 4.54876px, 0px) scale(1, 2.13719); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 2.28571px, 0px) scale(1, 1.57143); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 0.852965px, 0px) scale(1, 1.21324); transform-origin: 50% 50% 0px;",
                "height: 0.5rem; transform: translate3d(0px, 0.121968px, 0px) scale(1, 1.03049); transform-origin: 50% 50% 0px;"
            ],
        },
        'navClose': {
            'desktop': [
                "transform: translate3d(0px, -384.958px, 0px) scale(1, 0.0103896); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -384.957px, 0px) scale(1, 0.0103936); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -384.934px, 0px) scale(1, 0.0104537) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -384.929px, 0px) scale(1, 0.0104663) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -384.774px, 0px) scale(1, 0.0108637); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -384.673px, 0px) scale(1, 0.0111225); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -384.547px, 0px) scale(1, 0.0114478); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -384.171px, 0px) scale(1, 0.0124149); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -383.546px, 0px) scale(1, 0.0140218); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -382.473px, 0px) scale(1, 0.0167802); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -380.96px, 0px) scale(1, 0.0206693); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -379.083px, 0px) scale(1, 0.0254925); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -376.952px, 0px) scale(1, 0.0309719); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -374.283px, 0px) scale(1, 0.0378338); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -370.575px, 0px) scale(1, 0.0473647); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -367.057px, 0px) scale(1, 0.0564093); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -362.396px, 0px) scale(1, 0.0683916); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -356.698px, 0px) scale(1, 0.0830375); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -297.791px, 0px) scale(1, 0.23447); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -268.409px, 0px) scale(1, 0.310002); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -248.339px, 0px) scale(1, 0.361596); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -25.8555px, 0px) scale(1, 0.933533); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -0.320676px, 0px) scale(1, 0.999167); transform-origin: 50% 50% 0px;",
                "transform: none; transform-origin: 50% 50% 0px;"
            ],
            'tablet': [
                "transform: translate3d(0px, -396.96px, 0px) scale(1, 0.0100756); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -396.959px, 0px) scale(1, 0.0100765) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -396.958px, 0px) scale(1, 0.0100809); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -396.955px, 0px) scale(1, 0.0100866); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -396.932px, 0px) scale(1, 0.0101444); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -396.869px, 0px) scale(1, 0.0103017); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -396.758px, 0px) scale(1, 0.0105789); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -396.548px, 0px) scale(1, 0.0111021) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -396.172px, 0px) scale(1, 0.0120404); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -395.547px, 0px) scale(1, 0.0135995); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -394.473px, 0px) scale(1, 0.0162761); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -392.96px, 0px) scale(1, 0.0200509) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -391.202px, 0px) scale(1, 0.024434); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -388.824px, 0px) scale(1, 0.0303648); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -386.275px, 0px) scale(1, 0.036721) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -383.009px, 0px) scale(1, 0.0448661); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -379.033px, 0px) scale(1, 0.0547812); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -374.357px, 0px) scale(1, 0.066442) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -368.256px, 0px) scale(1, 0.0816555); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -360.374px, 0px) scale(1, 0.101311); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -348.889px, 0px) scale(1, 0.129952); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -338.732px, 0px) scale(1, 0.155282); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -320.616px, 0px) scale(1, 0.20046); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -298.889px, 0px) scale(1, 0.254642); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -267.152px, 0px) scale(1, 0.333786) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -228.436px, 0px) scale(1, 0.430334); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -174.374px, 0px) scale(1, 0.565153); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -110.542px, 0px) scale(1, 0.724334); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -46.4756px, 0px) scale(1, 0.884101); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -6.62476px, 0px) scale(1, 0.983479); transform-origin: 50% 50% 0px;",
                "transform: none; transform-origin: 50% 50% 0px;"
            ],
            'phone': [
                "transform: translate3d(0px, -563.972px, 0px) scale(1, 0.0070922); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -563.971px, 0px) scale(1, 0.00709286); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -563.969px, 0px) scale(1, 0.00709598); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -563.947px, 0px) scale(1, 0.00713606); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -563.886px, 0px) scale(1, 0.00724324); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -563.779px, 0px) scale(1, 0.0074321); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -563.592px, 0px) scale(1, 0.00776088); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -563.212px, 0px) scale(1, 0.00843007); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -562.609px, 0px) scale(1, 0.0094917) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -561.554px, 0px) scale(1, 0.0113479); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -560.063px, 0px) scale(1, 0.0139735) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -558.189px, 0px) scale(1, 0.0172733); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -556.06px, 0px) scale(1, 0.0210219) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -553.548px, 0px) scale(1, 0.0254441); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -550.313px, 0px) scale(1, 0.0311391); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -546.546px, 0px) scale(1, 0.0377719) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -541.636px, 0px) scale(1, 0.0464152) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -535.426px, 0px) scale(1, 0.0573493); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -527.468px, 0px) scale(1, 0.0713583) scale(1, 1); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -517.836px, 0px) scale(1, 0.0883162); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -504.2px, 0px) scale(1, 0.112324); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -485.728px, 0px) scale(1, 0.144845); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -459.752px, 0px) scale(1, 0.190578); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -423.846px, 0px) scale(1, 0.253792); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -374.741px, 0px) scale(1, 0.340245); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -302.23px, 0px) scale(1, 0.467904); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -206.545px, 0px) scale(1, 0.636364); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -99.8326px, 0px) scale(1, 0.824238); transform-origin: 50% 50% 0px;",
                "transform: translate3d(0px, -16.8069px, 0px) scale(1, 0.97041); transform-origin: 50% 50% 0px;",
                "transform: none; transform-origin: 50% 50% 0px;"
            ],
        },
        'wrapperClose': {
            'desktop': [
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 762px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 761.701px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 757.283px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 756.358px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 728.398px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 711.265px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 690.826px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 636.385px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 562.54px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 468.753px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 379.047px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 305.818px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 250.299px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 203.451px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 160.902px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 133.821px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 108.973px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 88.342px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 26.1194px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 17.8063px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 14.1241px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 0.569592px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 2.80702% 0.733153%; border-top-right-radius: 2.80702% 0.733153%; transform: translate3d(0px, 0.569592px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 40px; border-top-right-radius: 40px; transform: none; transform-origin: 50% 50% 0px;"
            ],
            'tablet': [
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 786px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 785.926px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 785.579px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 785.134px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 780.614px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 768.574px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 748.222px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 712.585px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 656.428px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 580.258px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 483.517px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 390.985px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 319.413px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 255.463px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 209.859px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 170.308px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 138.035px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 112.406px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 89.9726px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 70.9646px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 53.5612px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 43.5192px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 31.9083px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 23.4167px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 15.9674px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 10.5902px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 6.15545px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 3.04463px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 1.04874px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 5.06971% 0.568819%; border-top-right-radius: 5.06971% 0.568819%; transform: translate3d(0px, 0.134385px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 40px; border-top-right-radius: 40px; transform: none; transform-origin: 50% 50% 0px;"
            ],
            'phone': [
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 1120px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 1119.89px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 1119.4px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 1113.07px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 1096.48px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 1068.41px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 1022.81px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 940.984px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 834.842px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 696.975px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 564.512px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 455.143px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 372.555px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 306.415px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 248.912px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 203.798px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 164.357px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 131.496px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 104.11px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 82.5836px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 63.2224px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 47.2315px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 33.9776px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 23.5218px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 15.5125px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 9.09751px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 4.57141px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 1.70593px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 8.16327% 0.513076%; border-top-right-radius: 8.16327% 0.513076%; transform: translate3d(0px, 0.243935px, 0px); transform-origin: 50% 50% 0px;",
                "border-top-left-radius: 40px; border-top-right-radius: 40px; transform: none; transform-origin: 50% 50% 0px;"
            ],
        },
    },
    watchNav: async (value, oldValue) => {
        const headerEl = document.querySelector('#header');
        const navEl = document.querySelector('#nav');
        const wrapperEl = document.querySelector('#wrapper');
        const navMode = document.querySelector('#nav_mode');
        if (!value && navMode.value == 'true') {
            headerEl.style.cssText = 'height: 0.5rem; transform: scale(1, 1.00083); transform-origin: 50% 50% 0px;';
            navEl.style.cssText = 'transform: none; transform-origin: 50% 50% 0px;';
            wrapperEl.style.cssText = 'border-top-left-radius: 40px; border-top-right-radius: 40px; transform: none; transform-origin: 50% 50% 0px;';
            headerEl.style.transform = 'none';
            navEl.style.transform = 'none';
            wrapperEl.style.transform = 'none';
            
            navMode.value = false;

            adjustScroll();
            
            return;
        }

        if (value) {
            await HeaderObj.transformChange(headerEl, navEl, wrapperEl, value);
        } else {
            await HeaderObj.transformChange(headerEl, navEl, wrapperEl, value);
        }
    },
    /**
     * Transform header menu
     * @param {HTMLDivElement} headerEl 
     * @param {HTMLDivElement} navEl 
     * @param {HTMLDivElement} wrapperEl 
     * @param {boolean} value 
     */
    transformChange: async (headerEl, navEl, wrapperEl, value) => {
        const navMenuClientRect = headerEl.getBoundingClientRect();
        if (value) {
            if (navMenuClientRect.width < 640) {
                // mobile
                let increment = 0;
                const animationTimeout = 200 / HeaderObj.transformData.headerOpen.phone.length;
                for (const transformItem of HeaderObj.transformData.headerOpen.phone) {
                    headerEl.style.cssText = HeaderObj.transformData.headerOpen.phone[increment];
                    navEl.style.cssText = HeaderObj.transformData.navOpen.phone[increment]
                    wrapperEl.style.cssText = HeaderObj.transformData.wrapperOpen.phone[increment]
                    increment += 1;
                    await new Promise(resolve => setTimeout(resolve, animationTimeout)); 
                }
                headerEl.style.transform = 'none';
                navEl.style.transform = 'none';
                wrapperEl.style.transform = 'none';
            } else if (navMenuClientRect.width > 640 && navMenuClientRect.width < 1024) {
                // tablet
                let increment = 0;
                const animationTimeout = 200 / HeaderObj.transformData.headerOpen.tablet.length;
                for (const transformItem of HeaderObj.transformData.headerOpen.tablet) {
                    headerEl.style.cssText = HeaderObj.transformData.headerOpen.tablet[increment];
                    navEl.style.cssText = HeaderObj.transformData.navOpen.tablet[increment]
                    wrapperEl.style.cssText = HeaderObj.transformData.wrapperOpen.tablet[increment]
                    increment += 1;
                    await new Promise(resolve => setTimeout(resolve, animationTimeout)); 
                }
                headerEl.style.transform = 'none';
                navEl.style.transform = 'none';
                wrapperEl.style.transform = 'none';
            } else  {
                // desktop
                let increment = 0;
                const animationTimeout = 200 / HeaderObj.transformData.headerOpen.desktop.length;
                for (const transformItem of HeaderObj.transformData.headerOpen.desktop) {
                    headerEl.style.cssText = HeaderObj.transformData.headerOpen.desktop[increment];
                    navEl.style.cssText = HeaderObj.transformData.navOpen.desktop[increment]
                    wrapperEl.style.cssText = HeaderObj.transformData.wrapperOpen.desktop[increment]
                    increment += 1;
                    await new Promise(resolve => setTimeout(resolve, animationTimeout)); 
                }
                headerEl.style.transform = 'none';
                navEl.style.transform = 'none';
                wrapperEl.style.transform = 'none';
            }
        } else {
            if (navMenuClientRect.width < 640) {
                // mobile
                let increment = 0;
                const animationTimeout = 200 / HeaderObj.transformData.headerClose.phone.length;
                for (const transformItem of HeaderObj.transformData.headerClose.phone) {
                    headerEl.style.cssText = HeaderObj.transformData.headerClose.phone[increment];
                    navEl.style.cssText = HeaderObj.transformData.navClose.phone[increment]
                    wrapperEl.style.cssText = HeaderObj.transformData.wrapperClose.phone[increment]
                    increment += 1;
                    await new Promise(resolve => setTimeout(resolve, animationTimeout)); 
                }
                headerEl.style.transform = 'none';
                navEl.style.transform = 'none';
                wrapperEl.style.transform = 'none';
            } else if (navMenuClientRect.width > 640 && navMenuClientRect.width < 1024) {
                // tablet
                let increment = 0;
                const animationTimeout = 200 / HeaderObj.transformData.headerClose.tablet.length;
                for (const transformItem of HeaderObj.transformData.headerClose.tablet) {
                    headerEl.style.cssText = HeaderObj.transformData.headerClose.tablet[increment];
                    navEl.style.cssText = HeaderObj.transformData.navClose.tablet[increment]
                    wrapperEl.style.cssText = HeaderObj.transformData.wrapperClose.tablet[increment]
                    increment += 1;
                    await new Promise(resolve => setTimeout(resolve, animationTimeout)); 
                }
                headerEl.style.transform = 'none';
                navEl.style.transform = 'none';
                wrapperEl.style.transform = 'none';
            } else  {
                // desktop
                let increment = 0;
                const animationTimeout = 200 / HeaderObj.transformData.headerClose.desktop.length;
                for (const transformItem of HeaderObj.transformData.headerClose.desktop) {
                    headerEl.style.cssText = HeaderObj.transformData.headerClose.desktop[increment];
                    navEl.style.cssText = HeaderObj.transformData.navClose.desktop[increment]
                    wrapperEl.style.cssText = HeaderObj.transformData.wrapperClose.desktop[increment]
                    increment += 1;
                    await new Promise(resolve => setTimeout(resolve, animationTimeout)); 
                }
                headerEl.style.transform = 'none';
                navEl.style.transform = 'none';
                wrapperEl.style.transform = 'none';
            }
        }
    },
}

