// Header Scripts
async function navOpenInit(value, oldValue) {
  if (typeof HeaderObj != "undefined") {
    HeaderObj?.watchNav(value, oldValue);
  }
}

// Fade in section animation
window.addEventListener('DOMContentLoaded', function(evt) {
  const PageData = {
    sectionList: [],
    observers: [],
    /**
     * @param {HTMLDivElement} element 
     */
    applyFadeIn: (element) => {
      if (element) {
        element.classList.add('animate-once', 'animate-fade-up');
      }
    },
    sectionBgAnimationInit: () => {
      const recContentEls = document.querySelectorAll('#recContent');
      Array.from(recContentEls).forEach(recContentEl => {
        const paths = recContentEl.querySelectorAll('path');
        Array.from(paths).forEach(pathEl => {
          pathEl.addEventListener('mouseenter', (evt) => {
            const currentPathEl = evt.target;
            const clonedPath = currentPathEl.clonedPath(true);
          })
        })
      });
    }
  }

  // add all sections
  PageData.sectionList = Array.from(this.document.querySelectorAll('.el-section-fade-in') ?? []);

  // register section scroll listener
  const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: '0px',
      threshold: 0.1 // Trigger when 10% of the element is visible
  };
  const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
          if (entry.isIntersecting) {
              PageData.applyFadeIn(entry.target);
              observer.unobserve(entry.target); // Stop observing after fade-in is applied
          }
      });
  };

  PageData.sectionList.forEach(sectionEl => {
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    PageData.observers.push(observer);
    observer.observe(sectionEl);

    // Check if the element is already in view on initial load
    const rect = sectionEl.getBoundingClientRect();
    if (rect.top < window.innerHeight && rect.bottom >= 0) {
        PageData.applyFadeIn(sectionEl);
        observer.unobserve(sectionEl);
    }
  })
})

function adjustScroll() {
  const elementId = window.location.hash.substring(1);
  const element = document.getElementById(elementId);

  if (element) {
      let offset = 168;
      const elementPosition = element.getBoundingClientRect();

      if (element.classList.contains('box-model') && element.classList.contains('has-image')) {
        offset += 128;
      } else if (element.classList.contains('box-model')) {
        offset += 64;
      } else if (element.classList.contains('has-image')) {
        offset += 64;
      } else if (element.classList.contains('box-contact')) {
        if (elementPosition.width < 640) {
          offset += -128;
        } else {
          offset += -208;
        }
      } else if (element.classList.contains('project-detail')) {
        offset += -128;
      } else if (element.classList.contains('work-inquiries')) {
        offset += -94;
      }

      window.scrollBy({
          top: elementPosition.top - offset,
          left: 0,
          behavior: 'smooth'
      });
  }
}

window.addEventListener('load', ()=> {
  if (window.location.hash) {
    adjustScroll();;
  }

  // Event listener for hash link clicks
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      if (window.location.pathname === '' || window.location.pathname === '/') {
        e.preventDefault();
        window.location.hash = this.getAttribute('href');
        document.querySelector('#nav_mode').value = true;
        const btnNavHeaderCloseEl = document.querySelector('#btnNavHeaderClose');
        btnNavHeaderCloseEl.click();
      }
    });
  });
})

