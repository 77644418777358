const ErrorLoggerObj = {
    saveErrorLog: async function (error) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        
        if (typeof XMLHttpRequestHelper !== 'undefined' || typeof window.XMLHttpRequestHelper !== 'undefined') {
            try {
                const dataObj = ErrorLoggerObj.createFormData({
                    'token' : token,
                    'filename' : error.filename,
                    'lineno' : error.lineno,
                    'message' : error.message,
                });

                const xhrHelper = new XMLHttpRequestHelper();
                await xhrHelper.post('/error-logger/save', dataObj);
            } catch (error) {
                console.log(error.message);
            }
        }          
    },

    createFormData: function(values) {
        const formDataObj = new FormData();

        for (var key in values) {
            formDataObj.append(key, values[key]);
        }

        return formDataObj;
    }
}