function XMLHttpRequestHelper() {
  this.xhr = new XMLHttpRequest();

  this.get = async function (url) {
    return this._sendRequest("GET", url);
  };

  this.post = async function (url, data = null) {
    return this._sendRequest("POST", url, data);
  };

  this._sendRequest = async function (method, url, data = null) {
    return new Promise((resolve, reject) => {
      this.xhr.open(method, url, true);

      if (method === "POST") {
        if (data instanceof FormData) {
          this.xhr.setRequestHeader("Accept", "application/json");
        } else if (data && typeof data === "object") {
          this.xhr.setRequestHeader("Content-Type", "application/json");
        }
      }

      this.xhr.onreadystatechange = () => {
        if (this.xhr.readyState === 4) {
          if (this.xhr.status === 200) {
            try {
              const response = JSON.parse(this.xhr.responseText);
              resolve(response);
            } catch (error) {
              reject(`Error parsing response: ${error.message}`);
            }
          } else {
            reject(`Error: ${this.xhr.status} - ${this.xhr.statusText}`);
          }
        }
      };

      if (data) {
        if (data instanceof FormData) {
          this.xhr.send(data);
        } else {
          this.xhr.send(JSON.stringify(data));
        }
      } else {
        this.xhr.send();
      }
    });
  };
}
